import { basisSlice } from './basis';

export { getApp, getStructure } from './actions';
export const {
  setApp,
  setLoading,
  setRoutes,
  setServerUrl,
  setSidebar,
} = basisSlice.actions;

export default basisSlice.reducer;
