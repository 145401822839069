import Vendor from './Vendor';

const VendorPage: Page = {
  path: '/vendors',
  title: 'Vendor',
  type: 'CRUDTable',
  model: Vendor,
};

export default VendorPage;
