import AccountingPage from './accounting/AccountingPage';
import ChartOfAccountPage from './accounts/ChartOfAccountPage';
import DashboardPage from './dashboard/DashboardPage';

const AccountingModulePage: Page = {
  path: '/accounting',
  title: 'Accounting',
  type: 'Route',
  pages: [DashboardPage, AccountingPage, ChartOfAccountPage],
};

export default AccountingModulePage;
