import InventoryPage from './inventory/InventoryPage';
import TransferOutPage from './transfer/TransferOutPage';

const InventoryModulePage: Page = {
  path: '/inventory',
  title: 'Inventory',
  type: 'Route',
  pages: [InventoryPage, TransferOutPage],
};

export default InventoryModulePage;
