import Customer from './Customer';

const CustomerPage: Page = {
  path: '/customers',
  title: 'Customer',
  type: 'CRUDTable',
  model: Customer,
};

export default CustomerPage;
