const Product: Model = {
  title: 'Product & Service',
  name: 'products',
  fields: [
    { name: 'name', label: 'Product/Service', type: 'string', searchable: true, lookUp: true },
    { name: 'description', label: 'Description', type: 'text' },
    { name: 'price', label: 'Price (IDR)', type: 'number' },
    { name: 'uom', label: 'UoM', type: 'string', defaultValue: 'pcs' },
    { name: 'sell', label: 'Can be Sold', type: 'boolean', lookUpHide: true },
    { name: 'buy', label: 'Can be Puchased', type: 'boolean', lookUpHide: true },
  ],
};

export default Product;
