import { Button, Classes, Divider, IconName } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useTypedSelector } from '../../../../stores';
import ConfirmDialog from '../../fragments/ConfirmDialog';
import useGet from '../../hooks/useGet';
import usePost from '../../hooks/usePost';
import WhiteCard from '../WhiteCard';

interface Props<T> {
  children?: React.ReactNode,
  duplicate?: boolean,
  form: T,
  model: Model,
  readOnly?: boolean,
  onDataFetched?: (data: T) => void,
}

const FormBase = <T extends object>({ children, duplicate, form, model, readOnly, onDataFetched }: Props<T>) => {
  const [showDelete, setShowDelete] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { serverUrl } = useTypedSelector(s => s.basis);

  const printFormatRef = React.useRef(null);

  const printContent = React.useCallback(() => {
    return printFormatRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: printContent,
    documentTitle: model.title,
    onBeforeGetContent: () => setPrintLoading(true),
    onAfterPrint: () => setPrintLoading(false),
    removeAfterPrint: true,
  });

  const baseUrl = `${serverUrl}/${model.name}`;
  const url = `${baseUrl}/${id}`;

  const [data, getData, loadingData ] = useGet<Model>({ url, manualCall: true });  
  const [put, putLoading] = usePost({ url, method: 'put', callback: () => navigate(-1) });
  const [post, postLoading] = usePost({ url: baseUrl, method: 'post', callback: () => {
    if (duplicate) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  }});
  const [remove, loadingRemove] = usePost({ url, method: 'delete', callback: () => navigate(-2) });

  useEffect(() => {
    if (id != null) {
      getData();
    }
  }, [getData, id, location.key]);

  useEffect(() => {
    if (data != null && typeof onDataFetched === 'function') {
      onDataFetched(data as T);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let actionIcon: IconName | undefined;
  let actionOnClick: () => void | undefined;

  if (data != null) {
    actionIcon = readOnly ? 'edit' : 'trash';
    actionOnClick = readOnly ? () => navigate(`${location.pathname}/edit`) : () => setShowDelete(true);
  }

  const tools: React.ReactNode[] = [];

  if (readOnly && model.printFormat != null) {
    tools.push(
      <Button
        large
        minimal
        disabled={printFormatRef.current == null}
        icon="print"
        key="print"
        loading={printLoading}
        onClick={handlePrint}
      />
    );
  }

  if (readOnly && model.actions != null && model.actions.length > 0) {
    for (let i = 0; i < model.actions.length; i++) {
      const action = model.actions[i];

      if (action === 'Duplicate') {
        tools.push(
          <Button
            large
            minimal
            disabled={data == null}
            icon="duplicate"
            key={`${action}${i}`}
            onClick={() => navigate(`${location.pathname}/duplicate`)}
          />
        );
      }
    }
  }

  return (
    <WhiteCard
      title={id == null ? `Add ${model.title}` : model.title}
      actionIcon={actionIcon}
      actionOnClick={() => actionOnClick()}
      tools={tools}
    >
      <div
        className={Classes.DIALOG_BODY}
        style={{
          display: readOnly && model.view != null ? 'none' : 'block',
        }}
      >
        {children}
      </div>

      {readOnly && model.view != null && data != null && (
        <div style={{ margin: -20 }}>
          <model.view data={data} />
        </div>
      )}

      {model.printFormat != null && (
        <div style={{ display: 'none' }}>
          <model.printFormat ref={printFormatRef} data={data} />
        </div>
      )}

      {!readOnly && (
        <>
          <Divider style={{ margin: '8px -20px 20px' }} />

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon="floppy-disk"
                intent="primary"
                loading={postLoading || putLoading || loadingData}
                text="Save"
                onClick={() => {
                  if (duplicate) {
                    const f: any = {...form};
                    delete f._id;
                    post(f);
                    return;
                  }

                  if (id == null) {
                    post(form);
                  } else {
                    put(form);
                  }
                }}
              />
            </div>
          </div>

          <ConfirmDialog
            cancelLabel="Cancel"
            confirmLabel="Delete"
            confirmIntent="danger"
            icon="trash" 
            isOpen={showDelete}
            loading={loadingRemove}
            message={`Do you want to delete this ${model.title.toLowerCase()}? This action cannot be undone.`}
            title={`Delete ${model.title}`}
            onCancel={() => setShowDelete(false)}
            onConfirm={() => remove()}
          />
        </>
      )}
    </WhiteCard>
  );
};

export default FormBase;
