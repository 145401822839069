import SalesOrder from './SalesOrder';

const SalesOrderPage: Page = {
  path: '/orders',
  title: 'Sales Order',
  type: 'CRUDTable',
  model: SalesOrder,
};

export default SalesOrderPage;
