import React from 'react';
import BasisApp from './basis/BasisApp';
import { routes } from './routes';

const App: React.FC = () => {
  return (
    <BasisApp
      routes={routes}
      serverUrl={`http://srvgmk.diatera.com:8002`}
    />
  );
};

export default App;
