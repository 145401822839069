import { AnchorButton, Button, Card, ControlGroup, Divider, InputGroup, Navbar } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../../../../stores';
import { composeFields } from '../../../utils/form';
import TableComponent from '../../elements/Table';
import generateRows from '../../elements/Table/generateRows';
import useForeignKey from '../../hooks/useForeignKey';
import useGet from '../../hooks/useGet';

interface Props {
  model: Model,
  addUrl?: string,
  customAction?: boolean,
  onClick?: (id: string) => void,
}

const Table: React.FC<Props> = ({ model, addUrl, customAction, onClick }) => {
  const [search, setSearch] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const { serverUrl } = useTypedSelector(s => s.basis);
  
  const url = `${serverUrl}/${model.name}`;
  const [data, get, getLoading] = useGet<Generic[]>({ url });
  const { fks } = useForeignKey(model, data);

  const searchableFields = model.fields.filter(f => f.searchable).map(f => f.name);
  const orderedFields = model.fields
    .filter(f => f.order != null)
    .map(f => ({ name: f.name, order: f.order}));

  const headers = [
    '#',
    ...composeFields(model)
      .filter(v => !v.hideColumn)
      .filter(v => v.type !== 'array' && v.type !== 'model')
      .map(v => {
        if (v.type === 'number') {
          return (
            <span style={{ display: 'block', width: '80%', textAlign: 'right' }}>
              {v.label}
            </span>
          )
        }

        return v.label;
      }),
  ];

  const filtered = data == null ? [] : data.filter(d => {
    if (searchableFields.length === 0) {
      return true;
    }

    for (let i = 0; i < searchableFields.length; i++) {
      const fieldName = searchableFields[i];
      const value = d[fieldName]?.toString().toLowerCase();
      if (value?.indexOf(search.toLowerCase()) > -1) {
        return true;
      }
    }

    return false;
  });

  const ordered = filtered.sort((a, b) => {
    for (let i = 0; i < orderedFields.length; i++) {
      const fieldName = orderedFields[i].name;
      const order = orderedFields[i].order;

      const valueA = ('' + a[fieldName]).toLowerCase();
      const valueB = ('' + b[fieldName]).toLowerCase();
      
      if (order === 'asc') {
        return ('' + valueA).localeCompare(valueB);
      } else {
        return ('' + valueB).localeCompare(valueA);
      }
    }

    return 0;
  })

  const rows = generateRows(model, ordered, fks);

  return (
    <Card style={{ padding: '0 0 20px' }}>
      <Navbar>
        <Navbar.Group>
          <ControlGroup>
            {searchableFields.length > 0 && (
              <>
                <InputGroup
                  round
                  leftIcon="search"
                  placeholder="Search"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />

                <Divider />
              </>
            )}

            {(() => {
              if (customAction && addUrl == null) {
                return;
              }

              if (model.actions != null && model.actions.indexOf('Add') === -1) {
                return;
              }

              const url = customAction && addUrl != null ? `#${location.pathname}/${addUrl}` : `#${location.pathname}/add`;

              return (
                <AnchorButton
                  minimal
                  href={url}
                  icon="plus"
                  text="Add"
                />
              );
            })()}

          </ControlGroup>
        </Navbar.Group>

        <Navbar.Group align="right">
          <ControlGroup>
            <Button
              minimal
              icon="refresh"
              loading={getLoading}
              text="Refresh"
              onClick={() => get()}
            />
          </ControlGroup>
        </Navbar.Group>
      </Navbar>

      <TableComponent
        loading={getLoading}
        headers={headers}
        rows={rows}
        onClick={idx => {
          if (data == null) {
            return;
          }

          if (customAction && typeof onClick === 'function') {
            onClick(ordered[idx]._id);
          } else if (!customAction) {
            navigate(`${location.pathname}/${ordered[idx]._id}`);
          }
        }}
      />
    </Card>
  );
};

export default Table;
