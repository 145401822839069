import { Button, Classes, Divider, FormGroup, H4, InputGroup } from '@blueprintjs/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetForeignKey from '../../../../basis/components/hooks/useGetForeignKey';
import Product from '../../../products/Product';

const View: React.FC<{ data?: any }> = ({ data }) => {
  const [product, setProduct] = useState<Generic>();

  const navigate = useNavigate();
  const { getForeignKey } = useGetForeignKey();

  useEffect(()  => {
    getForeignKey(Product, data.product, (data) => {
      setProduct(data);
    });
  }, [data.product, getForeignKey]);

  return (
    <div style={{ padding: 20 }}>
      <div className={Classes.DIALOG_BODY}>
        <H4 style={{ marginBottom: 30 }}>{product?.name}</H4>

        <FormGroup inline className='form-inline' label="Stock">
          <InputGroup
            readOnly
            value={data.stock}
          />
        </FormGroup>

        <FormGroup inline className='form-inline' label="Unit of Measure">
          <InputGroup
            readOnly
            value={data.uom}
          />
        </FormGroup>

        <FormGroup inline className='form-inline' label="Location">
          <InputGroup
            readOnly
            value={data.location}
          />
        </FormGroup>
      </div>

      <Divider style={{ margin: '40px -20px' }} />

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon="inheritance"
            intent="primary"
            text="Transfer Out"
            onClick={() => {
              navigate(
                '/inventory/transfer_out/add',
                {
                  replace: true,
                  state: {
                    _id: undefined,
                    name: product?.name,
                    date: format(new Date(), 'yyyy-MM-dd HH:mm') },
                },
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

class InventoryView extends React.PureComponent<{ data?: any }> {
  render() {
    if (this.props.data == null) {
      return (<></>);
    }

    return (
      <View data={this.props.data} />
    );
  }
}

export default InventoryView;