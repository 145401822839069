import ChartOfAccount from './ChartOfAccount';

const ChartOfAccountPage: Page = {
  path: '/chart_account',
  title: 'Chart of Account',
  type: 'CRUDTable',
  model: ChartOfAccount,
};

export default ChartOfAccountPage;
