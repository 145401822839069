import { format } from 'date-fns';
import Product from '../../products/Product';

const TransferOut: Model = {
  name: 'inventory_out',
  title: 'Transfer Out',
  fields: [
    { name: 'date', label: 'Date', type: 'string', defaultValue: format(new Date(), 'yyyy-MM-dd HH:mm'), readOnly: true },
    { name: 'product', label: 'Product/Service', type: 'foreign_key', foreignKey: { model: Product.name, field: 'name' } },
    { name: 'qty', label: 'Quantity', type: 'number' },
    { name: 'to', label: 'To', type: 'string' },
    { name: 'note', label: 'Note', type: 'text' },
  ],
};

export default TransferOut;
