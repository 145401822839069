const Contact: Model = {
  title:  'Contact',
  name: 'contacts',
  fields: [
    { name: 'name', label: 'Name', type: 'string' },
    { name: 'email', label: 'Email', type: 'string' },
    { name: 'address', label: 'Address', type: 'text' },
    { name: 'phone', label: 'Phone', type: 'string' },
  ],
};

const Vendor: Model = {
  title: 'Vendor',
  name: 'vendors',
  fields: [
    { name: 'name', label: 'Vendor', type: 'string', searchable: true, order: 'asc' },
    { name: 'address', label: 'Address', type: 'text' },
    { name: 'phone', label: 'Phone', type: 'string' },
    { name: 'contacts', label: 'contacts', type: 'array', model: Contact },
  ],
};

export default Vendor;
