import DashboardView from './DashboardView';

const DashboardPage: Page = {
  path: '/',
  title: 'Accounting',
  type: 'ReactNode',
  component: DashboardView,
};

export default DashboardPage;
