import { add, format } from 'date-fns';
import Product from '../../products/Product';
import Vendor from '../vendor/Vendor';
import PurchaseOrderView from './views/PurchaseOrderView';

const Payment: Model = {
  name: 'payment',
  title: 'Payment Terms',
  fields: [
    { name: 'term', label: 'Term', type: 'string', lookUp: true },
    { name: 'percentage', label: 'Percentage (%)', type: 'number' },
    { name: 'status', label: 'Status', type: 'enum', enum: ['Open', 'Paid'], defaultValue: 'Open' },
  ],
};

const ItemModel: Model = {
  name: 'items',
  title: 'Item',
  extend: Product,
  fields: [
    { name: 'qty', label: 'Qty', type: 'number', defaultValue: 1 },
  ],
};

const PurchaseOrder: Model = {
  name: 'purchases',
  title: 'Purchase Order',
  printFormat: PurchaseOrderView,
  view: PurchaseOrderView,
  fields: [
    { name: 'no', label: 'No', type: 'string', searchable: true, readOnly: true, defaultValue: `P${format(new Date(), 'yyMM')}`, order: 'desc' },
    { name: 'vendor', label: 'Vendor', type: 'foreign_key', foreignKey: { model: Vendor.name, field: 'name' } },
    { name: 'vendor_ref', label: 'Vendor Ref.', type: 'string', searchable: true },
    { name: 'attn', label: 'Attn', type: 'string' },
    { name: 'date', label: 'Date', type: 'date', defaultValue: new Date() },
    { name: 'delivery_date', label: 'Delivery Date', type: 'date', defaultValue: add(new Date(), { days: 60 }) },
    { name: 'ppn', label: 'PPN', type: 'boolean', defaultValue: true, hideColumn: true },
    { name: 'discount', label: 'Discount (%)', type: 'number', defaultValue: 0, hideColumn: true },
    { name: 'attachment', label: 'Attachment', type: 'string', hideColumn: true },
    { name: 'note', label: 'Note', type: 'text', hideColumn: true },
    { name: 'items', label: 'Items', type: 'array', model: ItemModel },
    { name: 'payment', label: 'Payment Terms', type: 'array', model: Payment },
    { name: 'status', label: 'Status', type: 'enum', enum: ['Open', 'Confirmed', 'Delivered'], defaultValue: 'Open' },
  ],
};

export default PurchaseOrder;

