const ChartOfAccount: Model = {
  name: 'chart_accounts',
  title: 'Chart of Accounts',
  fields: [
    { name: 'code', label: 'Code', type: 'string', order: 'asc' },
    { name: 'name', label: 'Name', type: 'string' },
  ],
};

export default ChartOfAccount;
