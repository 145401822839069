import { Card, Colors, H2, H4 } from '@blueprintjs/core';
import React from 'react';
import useGet from '../../../basis/components/hooks/useGet';
import { useTypedSelector } from '../../../stores';

const DashboardView: React.FC = () => {
  const { serverUrl } = useTypedSelector(s => s.basis);
  const [accounts] = useGet<Generic[]>({ url: `${serverUrl}/chart_accounts` });

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
      {accounts != null && accounts.filter(a => a.code === '101' || a.code === '102').map(account => (
        <Card style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <div>
            <H4><span style={{ color: Colors.GRAY2 }}>{account.code}</span> - {account.name}</H4>
          </div>
          <H2 style={{ flex: 1, textAlign: 'right' }}>
            Rp. {parseFloat(account.balance).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
          </H2>
        </Card>
      ))}
    </div>
  );
};

export default DashboardView;
