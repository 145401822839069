import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../basis/components/layouts/Table';
import Invoice from '../Invoice';

const InvoiceTable: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Table
      customAction
      model={Invoice}
      onClick={id => navigate(`/sales/invoices/${id}`)}
    />
  );
};

export default InvoiceTable;
