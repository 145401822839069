import Accounting from './Accounting';

const AccountingPage: Page = {
  path: '/transactions',
  title: 'Transactions',
  type: 'CRUDTable',
  model: Accounting,
};

export default AccountingPage;
