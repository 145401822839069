import Product from './Product';

const ProductPage: Page = {
  path: '/products',
  title: 'Products & Services',
  type: 'CRUDTable',
  model: Product,
};

export default ProductPage;
