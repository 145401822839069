import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../../../../stores';
import useGet from '../../hooks/useGet';
import Suggest, { SuggestItem } from '../Suggest';

interface Props {
  disabled?: boolean,
  field: Field,
  foreignKey: ForeignKey,
  readOnly?: boolean,
  value: string,
  values: any,
  onChange: (value: any) => void,
}

const InputSuggest: React.FC<Props> = ({ disabled, field, foreignKey, readOnly, value, values, onChange }) => {
  const navigate = useNavigate();
  const { serverUrl } = useTypedSelector(s => s.basis);

  const url = `${serverUrl}/${foreignKey.model}`;
  const [data, , loading] = useGet({ url });

  const items: SuggestItem[] = useMemo(() => {
    if (data == null) {
      return [];
    }

    return (data as Generic[])
      .filter(f => {
        if (foreignKey.filter == null) {
          return true;
        }

        return values[foreignKey.filter] === f[foreignKey.filter];
      })
      .sort((a, b) => ('' + a.name).localeCompare(b.name))
      .map(d => {
        let label = '';
        if (foreignKey.fieldLabel != null) {
          label = d[foreignKey.fieldLabel];
        }

        if (field.lookUp) {
          return { _id: d._id, id: d[foreignKey.field], name: d[foreignKey.field], label };
        }

        return { _id: d._id, id: d._id, name: d[foreignKey.field], label };
      });
  }, [data, field.lookUp, foreignKey.field, foreignKey.fieldLabel, foreignKey.filter, values]);

  return (
    <Suggest
      disabled={disabled || readOnly}
      readOnly={readOnly}
      loading={loading}
      selectedId={value}
      items={items}
      onSelect={(item) => onChange(item?._id)}
      onCreate={(query) => navigate(`/${foreignKey.model}/add`, { state: { [foreignKey.field]: query } })}
    />
  );
};

export default InputSuggest;
