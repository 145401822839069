import CustomerPage from './customers/CustomerPage';
import InvoicePage from './invoice/InvoicePage';
import SalesOrderPage from './order/SalesOrderPage';
import QuotationPage from './quotation/QuotationPage';

const SalesPage: Page = {
  path: '/sales',
  title: 'Sales',
  type: 'Route',
  pages: [QuotationPage, SalesOrderPage, InvoicePage, CustomerPage],
};

export default SalesPage;
