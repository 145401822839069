import { lazy } from 'react';
import { load } from './basis/routes/load';
import AccountingPage from './modules/accounting/AccountingModulePage';
import SidebarPage from './modules/dev/sidebar';
import InventoryPage from './modules/inventory/InventoryPage';
import ProductPage from './modules/products/ProductPage';
import PurchasesPage from './modules/purchases/PurchasesPage';
import SalesPage from './modules/sales/SalesPage';
import UserRoutePage from './modules/users';

const DashboardPage = load(lazy(() => import('./modules/dashboard/DashboardPage')));

export const routes: Route[] = [
  { href: '/', page: DashboardPage },
  { href: '/accounting', page: AccountingPage },
  { href: '/inventory', page: InventoryPage },
  { href: '/products', page: ProductPage },
  { href: '/purchases', page: PurchasesPage },
  { href: '/sales', page: SalesPage },
  { href: '/users', page: UserRoutePage },
  { href: '/sidebars', page: SidebarPage },
];
