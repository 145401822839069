import React, { ReactNode, useEffect, useState } from 'react';
import { HTMLTable, NonIdealState, Spinner } from '@blueprintjs/core';
import styles from './table.module.css';
import Pagination from '../Pagination';

interface Props {
  className?: string,
  headers: (string | JSX.Element)[],
  loading?: boolean,
  rows: (string | ReactNode)[][],
  rowCount?: number,
  widths?: (string | number)[],
  onClick?: (idx: number) => void,
}

const NoData: React.FC = () => (
  <NonIdealState
    icon="search"
    title="No Data"
  />
);

const Table: React.FC<Props> = ({ className, rows, headers, rowCount, loading, widths, onClick }) => {
  const [page, setPage] = useState(0);

  const row = rowCount == null ? 10 : rowCount;
  const pageCount = Math.ceil(rows.length / row);

  const renderBody = () => {
    if (loading || !rows.length) {
      return (
        <tr>
          <td className={styles.empty} colSpan={headers.length}>
            {loading ? <Spinner intent="primary" size={20} /> : <NoData />}
          </td>
        </tr>
      );
    }

    const start = page * row;
    const slice = rows.slice(start, start + row);

    return slice.map((value, i) => {
      const tds = value.map((v, j) => <td key={j}>{v}</td>);
      return <tr key={i} onClick={() => onClick && onClick(i + (page * row))}>{tds}</tr>;
    });
  };

  useEffect(() => {
    setPage(0);
  }, [rows.length]);

  return (
    <div className={[styles.root, className].filter(Boolean).join(' ')}>
      <HTMLTable interactive={!!rows.length} striped={!!rows.length}>
        <thead>
          <tr>
            {headers.map((v, i) => (
              <th key={i} style={{ width: widths != null ? widths[i] : undefined }}>
                {v}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </HTMLTable>

      <Pagination
        className={styles.pagination}
        index={page}
        maxButton={10}
        pageCount={pageCount}
        onChange={setPage}
      />
    </div>
  );
};

export default Table;
