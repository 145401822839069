import Invoice from './Invoice';
import InvoiceCreate from './views/InvoiceCreate';
import InvoiceTable from './views/InvoiceTable';

const InvoiceAdd: Page = {
  path: '/add/:order',
  title: 'Create Invoice',
  type: 'ReactNode',
  component: InvoiceCreate,
};

const InvoiceViewPage: Page = {
  path: '/:id',
  title: 'Invoice',
  type: 'FormView',
  model: Invoice,
};

const InvoiceEdit: Page = {
  path: '/:id/edit',
  title: 'Invoice',
  type: 'Form',
  model: Invoice,
};

const InvoiceList: Page = {
  path: '/',
  title: 'Invoice',
  type: 'ReactNode',
  component: InvoiceTable,
};

const InvoicePage: Page = {
  path: '/invoices',
  title: 'Invoice',
  type: 'Route',
  pages: [InvoiceList, InvoiceAdd, InvoiceViewPage, InvoiceEdit],
};

export default InvoicePage;
