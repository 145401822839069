import { add, format } from 'date-fns';
import Customer from '../customers/Customer';
import ItemModel from './Item';
import QuotationPrint from './views/QuotationPrint';

const Quotation: Model = {
  name: 'quotations',
  title: 'Quotation',
  printFormat: QuotationPrint,
  view: QuotationPrint,
  actions: ['Add', 'Duplicate'],
  fields: [
    { name: 'no', label: 'No', type: 'string', searchable: true, readOnly: true, defaultValue: `Q${format(new Date(), 'yyMM')}`, order: 'desc' },
    { name: 'date', label: 'Date', type: 'date', defaultValue: new Date() },
    { name: 'expire', label: 'Expire', type: 'date', defaultValue: add(new Date(), { days: 30 }) },
    { name: 'customer', label: 'Customer', type: 'foreign_key', foreignKey: { model: Customer.name, field: 'name' } },
    { name: 'attn', label: 'Attn', type: 'string' },
    { name: 'note', label: 'Note', type: 'text', hideColumn: true },
    { name: 'status', label: 'Status', type: 'enum', enum: ['Open', 'Expired', 'Sales Order', 'Cancelled'], defaultValue: 'Open' },
    { name: 'ppn', label: 'PPN', type: 'boolean', defaultValue: true, hideColumn: true },
    { name: 'discount', label: 'Discount (%)', type: 'number', defaultValue: 0, hideColumn: true },
    { name: 'items', label: 'Items', type: 'array', model: ItemModel },
  ],
};

export default Quotation;
