import TransferOut from './TransferOut';

const TransferOutPage: Page = {
  path: '/transfer_out',
  title: 'Transfer Out',
  type: 'CRUDTable',
  model: TransferOut,
};

export default TransferOutPage;
