const SidebarModel: Model = {
  title: 'Sidebar',
  name: 'sidebars',
  description: '',
  fields: [
    { label: 'Section', name: 'section', type: 'enum', enum: ['main', 'settings', 'developer'] },
    { label: 'Text', name: 'text', type: 'string', searchable: true },
    { label: 'Icon', name: 'icon', type: 'icon' },
    { label: 'Href', name: 'href', type: 'string' },
  ],
};

const SidebarPage: Page = {
  path: '/',
  title: 'Sidebar',
  type: 'CRUDTable',
  model: SidebarModel,
};

export default SidebarPage;
