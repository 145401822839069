import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Form, { createObj } from '../../../../basis/components/elements/Form';
import FormBase from '../../../../basis/components/layouts/FormBase';
import { composeFields } from '../../../../basis/utils/form';
import Invoice from '../Invoice';

const InvoiceCreate: React.FC = () => {
  const [form, setForm] = useState<any>(createObj(composeFields(Invoice)));

  const { state } = useLocation();

  useEffect(() => {
    if (typeof state === 'object') {
      setForm((f: any) => ({ ...f, ...state }));
    }
  }, [state]);

  return (
    <FormBase
      form={form}
      model={Invoice}
      onDataFetched={(data: any) => setForm(data)}
    >
      <Form
        inline
        fields={composeFields(Invoice)}
        values={form}
        onChange={f => setForm(f)}
      />
    </FormBase>
  );
};

export default InvoiceCreate;
