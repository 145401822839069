import Product from '../../products/Product';
import InventoryView from './views/InventoryView';

const Inventory: Model = {
  name: 'inventory',
  title: 'Inventory',
  view: InventoryView,
  fields: [
    { name: 'product', label: 'Product/Service', type: 'foreign_key', foreignKey: { model: Product.name, field: 'name' } },
    { name: 'stock', label: 'Stock', type: 'number', defaultValue: 1 },
    { name: 'uom', label: 'UoM', type: 'string', defaultValue: 'pcs' },
    { name: 'location', label: 'Location', type: 'string' },
  ],
};

export default Inventory;
