const Contact: Model = {
  title:  'Contact',
  name: 'contacts',
  fields: [
    { name: 'name', label: 'Name', type: 'string' },
    { name: 'email', label: 'Email', type: 'string' },
    { name: 'address', label: 'Address', type: 'text' },
    { name: 'phone', label: 'Phone', type: 'string' },
  ],
};

const Customer: Model = {
  title: 'Customer',
  name: 'customers',
  fields: [
    { name: 'name', label: 'Customer', type: 'string', searchable: true, order: 'asc' },
    { name: 'address', label: 'Address', type: 'text' },
    { name: 'phone', label: 'Phone', type: 'string' },
    { name: 'contacts', label: 'contacts', type: 'array', model: Contact },
  ],
};

export default Customer;
