import PurchaseOrder from './PurchaseOrder';

const PurchaseOrderPage: Page = {
  path: '/',
  title: 'Purchase Order',
  type: 'CRUDTable',
  model: PurchaseOrder,
};

export default PurchaseOrderPage;
