import Product from '../../products/Product';

const ItemModel: Model = {
  name: 'items',
  title: 'Item',
  extend: Product,
  fields: [
    { name: 'qty', label: 'Qty', type: 'number', defaultValue: 1 },
  ],
};

export default ItemModel;
