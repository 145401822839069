import PurchaseOrderPage from './order/PurchaseOrderPage';
import VendorPage from './vendor/VendorPage';

const PurchasesPage: Page = {
  path: '/purchases',
  title: 'Purchase',
  type: 'Route',
  pages: [PurchaseOrderPage, VendorPage],
};

export default PurchasesPage;
