import { add, format } from 'date-fns';
import Customer from '../customers/Customer';
import Quotation from '../quotation/Quotation';
import SalesOrderView from './views/SalesOrderView';

const Payment: Model = {
  name: 'payment',
  title: 'Payment Terms',
  fields: [
    { name: 'term', label: 'Term', type: 'string', lookUp: true },
    { name: 'percentage', label: 'Percentage (%)', type: 'number' },
    { name: 'status', label: 'Status', type: 'enum', enum: ['Open', 'Invoice', 'Paid'], defaultValue: 'Open' },
  ],
};

const SalesOrder: Model = {
  name: 'orders',
  title: 'Sales Order',
  printFormat: SalesOrderView,
  view: SalesOrderView,
  fields: [
    { name: 'no', label: 'No', type: 'string', searchable: true, readOnly: true, defaultValue: `S${format(new Date(), 'yyMM')}`, order: 'desc' },
    { name: 'customer', label: 'Customer', type: 'foreign_key', foreignKey: { model: Customer.name, field: 'name' } },
    { name: 'quotation', label: 'Quotation', type: 'foreign_key', foreignKey: { model: Quotation.name, field: 'no', filter: 'customer' } },
    { name: 'customer_ref', label: 'Customer Ref.', type: 'string', searchable: true },
    { name: 'date', label: 'Date', type: 'date', defaultValue: new Date() },
    { name: 'delivery_date', label: 'Delivery', type: 'date', defaultValue: add(new Date(), { days: 60 }) },
    { name: 'attachment', label: 'Attachment', type: 'string', hideColumn: true },
    { name: 'note', label: 'Note', type: 'text', hideColumn: true },
    { name: 'payment', label: 'Payment Terms', type: 'array', model: Payment },
    { name: 'status', label: 'Status', type: 'enum', enum: ['Open', 'On Progress', 'Delivered', 'Hand Over', 'Paid'], defaultValue: 'Open' },
  ],
};

export default SalesOrder;
