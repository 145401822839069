import { Button, IconName, MenuItem, Spinner } from '@blueprintjs/core';
import { Suggest2 } from '@blueprintjs/select';
import React from 'react';
import { SuggestItem } from '.';

const SuggestComponent = Suggest2.ofType<SuggestItem>();

interface Props {
  className?: string,
  disabled?: boolean,
  large?: boolean,
  loading?: boolean,
  fill?: boolean,
  icon?: IconName,
  items: SuggestItem[], 
  readOnly?: boolean,
  selectedId?: string | null,
  onSelect: (item: SuggestItem | null) =>  void,
  onCreate?: (query: string) => void,
}

const Suggest: React.FC<Props> = (props) => {
  const { className, disabled, readOnly, selectedId, large, loading, fill, icon, items, onSelect, onCreate } = props;

  return (
    <SuggestComponent
      className={className}
      fill={fill}
      disabled={disabled}
      items={items}
      inputValueRenderer={(item) => item.name}
      selectedItem={selectedId == null ? null : items.find(v => v.id === selectedId)}
      createNewItemFromQuery={onCreate == null ? undefined : () => ({ _id: '', id: '', name: '' })}
      createNewItemRenderer={onCreate == null ? undefined : (query, active) => (
        <MenuItem
          icon="add"
          text={`Create "${query}"`}
          label="Note: You will be redirected to create page"
          active={active}
          onClick={() => onCreate(query)}
          shouldDismissPopover={false}
      />
      )}
      itemRenderer={(item, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }

        return (
          <MenuItem
            active={modifiers.active}
            text={highlightText(item.name, query)}
            label={item.label}
            key={item.id}
            onClick={handleClick}
          />
        );
      }}
      itemPredicate={(query, item, _index, exactMatch) => {
        const normalizedTitle = item.name.toString().toLowerCase();
        const normalizedQuery = query.toLowerCase();

        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return normalizedTitle.indexOf(normalizedQuery) >= 0;
        }
      }}
      onItemSelect={(item) => {
        onSelect(item);
      }}
      inputProps={{
        className,
        large,
        readOnly,
        leftIcon: icon,
        placeholder: '',
        rightElement:
          readOnly ? undefined : 
          loading ? (
            <Spinner size={16} />
          ) : (
          <Button
            minimal
            small
            icon="cross"
            onClick={() => onSelect(null)}
          />
          ),
      }}
      popoverProps={{ minimal: true }}
      noResults={<MenuItem disabled={true} text="No results." />}
    />
  );
};

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
      .split(/\s+/)
      .filter(word => word.length > 0)
      .map(escapeRegExpChars);
  if (words.length === 0) {
      return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
      const match = regexp.exec(text);
      if (!match) {
          break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
          tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
      tokens.push(rest);
  }
  return tokens;
}

export default Suggest;
