import { add, format } from 'date-fns';
import Customer from '../customers/Customer';
import SalesOrder from '../order/SalesOrder';
import Quotation from '../quotation/Quotation';
import InvoiceView from './views/InvoiceView';

const Invoice: Model = {
  name: 'invoices',
  title: 'Invoice',
  printFormat: InvoiceView,
  view: InvoiceView,
  fields: [
    { name: 'no', label: 'No', type: 'string', searchable: true, readOnly: true, defaultValue: `I${format(new Date(), 'yyMM')}`, order: 'desc' },
    { name: 'customer', label: 'Customer', type: 'foreign_key', readOnly: true, foreignKey: { model: Customer.name, field: 'name' } },
    { name: 'order', label: 'Sales Order', type: 'foreign_key', readOnly: true, foreignKey: { model: SalesOrder.name, field: 'no' } },
    { name: 'quotation', label: 'Quotation', type: 'foreign_key', readOnly: true, foreignKey: { model: Quotation.name, field: 'no', filter: 'customer' } },
    { name: 'term', label: 'Term', type: 'string' },
    { name: 'percentage', label: 'Percentage (%)', type: 'number' },
    { name: 'attn', label: 'Attention To', type: 'string', hideColumn: true },
    { name: 'date', label: 'Date', type: 'date', defaultValue: new Date(), hideColumn:true },
    { name: 'due_date', label: 'Payment Due', type: 'date', defaultValue: add(new Date(), { days: 30 }) },
    { name: 'attachment', label: 'Attachment', type: 'string', hideColumn: true },
    { name: 'method', label: 'Payment Method', type: 'text', hideColumn: true },
    { name: 'note', label: 'Note', type: 'text', hideColumn: true },
    { name: 'status', label: 'Status', type: 'enum', enum: ['Open', 'Late', 'Paid'], defaultValue: 'Open' },
  ],
};

export default Invoice;
