import axios from 'axios';
import { AppThunk } from '../../../stores';
import { getBearerHeader } from '../../utils/services';
import { setMessage } from '../ui';
import { setApp, setLoading, setSidebar } from '.';

export function getApp(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const url = `${getState().basis.serverUrl}/app`;
      const res = await axios.get(url);

      if (res.status === 200) {
        dispatch(setApp(res.data.data));
      } else {
        dispatch(setMessage({
          intent: 'danger',
          message: `${res.status} ${res.statusText}. ${res.data.message}`,
        }));
      }
    } catch (err: any) {
      const message = axios.isAxiosError(err) ? err.message : 'An unexpected error occurred.';
      dispatch(setMessage({ intent: 'danger', message }));
    }
    dispatch(setLoading(false));
  };
}

export function getStructure(token: string): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const url = `${getState().basis.serverUrl}/app/structures`;
      const res = await axios.get(url, { headers: getBearerHeader(token) });

      if (res.status === 200) {
        dispatch(setSidebar(res.data.data.sidebar));
      } else {
        dispatch(setMessage({
          intent: 'danger',
          message: `${res.status} ${res.statusText}. ${res.data.message}`,
        }));
      }
    } catch (err: any) {
      const message = axios.isAxiosError(err) ? err.message : 'An unexpected error occurred.';
      dispatch(setMessage({ intent: 'danger', message }));
    }
    dispatch(setLoading(false));
  };
}
