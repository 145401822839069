import { AnchorButton, Button } from '@blueprintjs/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../../assets/diatera.png';
import useGetForeignKey from '../../../../basis/components/hooks/useGetForeignKey';
import { newLineToBr } from '../../../../basis/utils/strings';
import Customer from '../../customers/Customer';
import Quotation from '../../quotation/Quotation';
import styles from './salesorder.module.css';

const View: React.FC<{ data: any }> = ({ data }) => {
  const [customer, setCustomer] = useState<Generic>();
  const [quotation, setQuotation] = useState<Generic>();
  const [ppn, setPpn] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const navigate = useNavigate();
  const { getForeignKey } = useGetForeignKey();

  useEffect(() => {
    if (quotation == null || quotation.items == null) {
      return;
    }

    const t = quotation.items.reduce((prev: number, item: any) => prev + (item.price * item.qty), 0);

    const disc = quotation.discount ? t * quotation.discount / 100 : 0;
    const st = t - disc;

    const ppn = quotation.ppn ? st * 11 / 100 : 0;
    const gt = st + ppn;

    setDiscount(disc);
    setPpn(ppn);
    setTotal(t);
    setSubTotal(st);
    setGrandTotal(gt);
  }, [quotation]);

  useEffect(() => {
    getForeignKey(Customer, data['customer'], c => setCustomer(c));
  }, [data, getForeignKey]);

  useEffect(() => {
    getForeignKey(Quotation, data['quotation'], q => setQuotation(q));
  }, [data, getForeignKey]);

  return (
    <div className={styles.root}>
      <div
        className={styles.watermark}
        style={{
          backgroundColor: (() => {
            switch (data.status) {
              case 'On Progress': return '#2D72D2';
              case 'Delivered': return '#7961DB';
              case 'Hand Over': return '#00A396';
              case 'Paid': return '#29A634';
              default: return '#8F99A8';
            }
          })(),
        }}
      >
        <span>{data.status}</span>
      </div>

      <div className={styles.header}>
        <img src={Logo} alt="logo" />

        <div>
          <h1>Sales Order</h1>
          <h5>PT. Diatera Cipta Teknologi</h5>
          <p>Centennial Tower, Lantai 29, Kav. 24-25 Unit D-E</p>
          <p>Jl. Jenderal Gatot Subroto No.27, Jakarta 12940</p>
          <p>(021) 30490108</p>
        </div>
      </div>

      <div className={styles.divider} />

      <div className={styles.form}>
        <div>
          <span>To</span>
          <h5>{customer?.name}</h5>
          <p>{newLineToBr(customer?.address)}</p>

          <span>Attn</span>
          <p>{quotation?.attn}</p>
        </div>

        <div>
          <div className={styles.quot}>
            <h5>Sales Order No</h5>
            <span>{data.no}</span>
          </div>

          <div className={styles.quot}>
            <h5>Customer Ref</h5>
            <span>{data.customer_ref}</span>
          </div>

          <div className={styles.quot}>
            <h5>Order Date</h5>
            <span>{format(new Date(data.date), 'dd MMMM yyyy')}</span>
          </div>

          <div className={styles.quot}>
            <h5>Delivery Date</h5>
            <span>{format(new Date(data.delivery_date), 'dd MMMM yyyy')}</span>
          </div>

          <div className={[styles.quot, styles.grand].join(' ')}>
            <h5>Grand Total</h5>
            <span>Rp. {grandTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
        </div>
      </div>

      <table cellSpacing={0} border={0} cellPadding={0} className={styles.table}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Items</th>
            <th>Quantity</th>
            <th>UoM</th>
            <th style={{ textAlign: 'right' }}>Price</th>
            <th style={{ textAlign: 'right' }}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {quotation != null && quotation.items != null && quotation.items.map((item: Generic, i: number) => {
            return (
              <React.Fragment key={i}>
                <tr className={styles.item}>
                  <td style={{ fontWeight: 500 }}>{item.name}</td>
                  <td style={{ textAlign: 'center' }}>{item.qty}</td>
                  <td style={{ textAlign: 'center' }}>{item.uom}</td>
                  <td style={{ textAlign: 'right' }}>Rp. {item.price?.toLocaleString()}.00</td>
                  <td style={{ textAlign: 'right' }}>Rp. {(item.qty * item.price).toLocaleString()}.00</td>
                </tr>

                <tr style={{ borderBottom: '1px solid #E5E8EB' }}>
                  <td colSpan={5}>{newLineToBr(item.description)}</td>
                </tr>
              </React.Fragment>
            );
          })}

          {discount > 0 && (
            <>
              <tr className={styles.tfoot}>
                <td colSpan={4} style={{ fontWeight: 600, textAlign: 'right' }}>Subtotal</td>
                <td style={{ fontWeight: 600, textAlign: 'right' }}>
                  Rp. {total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
              </tr>

              <tr>
              <td colSpan={4} style={{ textAlign: 'right' }}>Discount</td>
                <td style={{ textAlign: 'right' }}>
                  Rp. {discount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
              </tr>
            </>
          )}

          <tr className={discount === 0 ? styles.tfoot : ''}>
            <td colSpan={3}></td>
            <td style={{ borderTop: discount > 0 ? '1px solid #D3D8DE' : undefined, fontWeight: 600, textAlign: 'right' }}>
              {discount === 0 ? 'Subtotal' : 'Total'}
            </td>
            <td style={{ borderTop: discount > 0 ? '1px solid #D3D8DE' : undefined, fontWeight: 600, textAlign: 'right' }}>
              Rp. {subTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
          </tr>

          <tr>
            <td colSpan={4} style={{ textAlign: 'right' }}>VAT</td>
            <td style={{ textAlign: 'right' }}>
              Rp. {ppn.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
          </tr>

          <tr>
            <td colSpan={3}></td>
            <td style={{ borderTop: '1px solid #D3D8DE', fontWeight: 600, textAlign: 'right' }}>
              Grand Total
            </td>
            <td style={{ borderTop: '1px solid #D3D8DE', fontWeight: 600, textAlign: 'right' }}>
              Rp. {grandTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
          </tr>
        </tbody>
      </table>

      <div className={styles.divider} />
      <span className={styles['section-title']}>Note</span>
      <p style={{ marginBottom: '28pt' }}>{newLineToBr(data.note)}</p>

      <div className={styles.divider} />
      <span className={styles['section-title']}>Payment Terms</span>

      <table cellSpacing={0} border={0} cellPadding={0} className={styles.table}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Term</th>
            <th style={{ width: '100pt' }}>Percentage</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.payment.map((p: Generic) => (
            <tr key={p.term} className={styles['tr-border']}>
              <td>{p.term}</td>
              <td style={{ textAlign: 'center' }}>{p.percentage}%</td>
              <td style={{ textAlign: 'center', fontWeight: 600, textTransform: 'uppercase' }}>
                <span
                  style={{
                    backgroundColor: p.status === 'Paid' ? '#29A634' : p.status === 'Invoice' ? '#2D72D2' : '#8F99A8',
                    borderRadius: '20pt',
                    color: '#FFFFFF',
                    padding: '2pt 8pt',
                    margin: '0 auto',
                  }}
                >
                  {p.status}
                </span>
              </td>
              <td width="20%">
                {p.status === 'Open' && (
                  <Button
                    minimal
                    icon="credit-card"
                    text="Create Invoice"
                    onClick={e => {
                      e.stopPropagation();
                      const state = {
                        ...p,
                        customer: data.customer,
                        order: data._id,
                        quotation: data.quotation,
                       };
                      navigate(`/sales/invoices/add/${data.no}`, { state });
                    }}
                  />
                )}

                {(p.status === 'Invoice' || p.status === 'Paid') && p.invoice != null && (
                  <Button
                    minimal
                    icon="credit-card"
                    intent="primary"
                    text="See Invoice"
                    onClick={e => {
                      e.stopPropagation();
                      navigate(`/sales/invoices/${p.invoice}`, { state: { _id: undefined } });
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.divider} style={{ marginTop: '28pt' }} />
      <span className={styles['section-title']}>Attachment</span>
      <AnchorButton
        minimal
        disabled={data.attachment == null || data.attachment === ''}
        href={data.attachment}
        target="_blank"
        icon="paperclip"
        text="Open Attachment"
      />
    </div>
  );
};

class SalesOrderView extends React.PureComponent<{ data?: any }> {
  render() {
    if (this.props.data == null) {
      return (<></>);
    }

    return (
      <View data={this.props.data} />
    );
  }
}

export default SalesOrderView;
