import { format } from 'date-fns';
import ChartOfAccount from '../accounts/ChartOfAccount';

const Accounting: Model = {
  name: 'accounting',
  title: 'Transaction',
  fields: [
    { name: 'date', label: 'Date', type: 'date', defaultValue: format(new Date(), 'yyyy-MM-dd'), order: 'desc' },
    { name: 'time', label: 'Time', type: 'string', defaultValue: format(new Date(), 'HH:mm'), order: 'desc' },
    { name: 'from_account', label: 'From Account', type: 'foreign_key', foreignKey: { model: ChartOfAccount.name, field: 'name', fieldLabel: 'code' } },
    { name: 'to_account', label: 'To Account', type: 'foreign_key', foreignKey: { model: ChartOfAccount.name, field: 'name', fieldLabel: 'code' } },
    { name: 'amount', label: 'Amount', type: 'number' },
    { name: 'note', label: 'Note', type: 'string' },
  ],
};

export default Accounting;
