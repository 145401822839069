import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Logo from '../../../../assets/diatera.png';
import useGetForeignKey from '../../../../basis/components/hooks/useGetForeignKey';
import { newLineToBr } from '../../../../basis/utils/strings';
import Customer from '../../customers/Customer';
import styles from './quotation.module.css';

const QuotatioinPrintFormat: React.FC<{ data: any }> = ({ data }) => {
  const [customer, setCustomer] = useState<Generic>();
  const [ppn, setPpn] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const { getForeignKey } = useGetForeignKey();

  useEffect(() => {
    const t = data.items.reduce((prev: number, item: any) => prev + (item.price * item.qty), 0);

    const disc = data.discount ? t * data.discount / 100 : 0;
    const st = t - disc;

    const ppn = data.ppn ? st * 11 / 100 : 0;
    const gt = st + ppn;

    setDiscount(disc);
    setPpn(ppn);
    setTotal(t);
    setSubTotal(st);
    setGrandTotal(gt);
  }, [data]);

  useEffect(() => {
    getForeignKey(Customer, data['customer'], c => setCustomer(c));
  }, [data, getForeignKey]);

  return (
    <div className={styles.root}>
      {(data.status === 'Cancelled' || data.status === 'Sales Order' || data.status === 'Expired') && (
        <div
          className={styles.watermark}
          style={{
            backgroundColor: data.status === 'Expired' ? '#CD4246' : (
              data.status === 'Sales Order' ? '#29A634' : '#5F6B7C'
            ),
          }}
        >
          <span>{data.status}</span>
        </div>
      )}

      <div className={styles.header}>
        <img src={Logo} alt="logo" />

        <div>
          <h1>Quotation</h1>
          <h5>PT. Diatera Cipta Teknologi</h5>
          <p>Centennial Tower, Lantai 29, Kav. 24-25 Unit D-E</p>
          <p>Jl. Jenderal Gatot Subroto No.27, Jakarta 12940</p>
          <p>(021) 30490108</p>
        </div>
      </div>

      <div className={styles.divider} />

      <div className={styles.form}>
        <div>
          <span>To</span>
          <h5>{customer?.name}</h5>
          <p>{newLineToBr(customer?.address)}</p>

          <span>Attn</span>
          <p>{data.attn}</p>
        </div>

        <div>
          <div className={styles.quot}>
            <h5>Quotation Number</h5>
            <span>{data.no}</span>
          </div>

          <div className={styles.quot}>
            <h5>Quotation Date</h5>
            <span>{format(new Date(data.date), 'dd MMMM yyyy')}</span>
          </div>

          <div className={styles.quot}>
            <h5>Expires On</h5>
            <span>{format(new Date(data.expire), 'dd MMMM yyyy')}</span>
          </div>

          <div className={[styles.quot, styles.grand].join(' ')}>
            <h5>Grand Total</h5>
            <span>Rp. {grandTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
        </div>
      </div>

      <table cellSpacing={0} border={0} cellPadding={0} className={styles.table}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Items</th>
            <th>Quantity</th>
            <th>UoM</th>
            <th style={{ textAlign: 'right' }}>Price</th>
            <th style={{ textAlign: 'right' }}>Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item: Generic, i: number) => {
            return (
              <React.Fragment key={i}>
                <tr style={{ backgroundColor: '#EDEFF2' }}>
                  <td style={{ fontWeight: 500 }}>{item.name}</td>
                  <td style={{ textAlign: 'center' }}>{item.qty}</td>
                  <td style={{ textAlign: 'center' }}>{item.uom}</td>
                  <td style={{ textAlign: 'right' }}>Rp. {item.price?.toLocaleString()}.00</td>
                  <td style={{ textAlign: 'right' }}>Rp. {(item.qty * item.price).toLocaleString()}.00</td>
                </tr>

                <tr>
                  <td>{newLineToBr(item.description)}</td>
                </tr>
              </React.Fragment>
            );
          })}

          {discount > 0 && (
            <>
              <tr className={styles.tfoot}>
                <td colSpan={4} style={{ fontWeight: 600, textAlign: 'right' }}>Subtotal</td>
                <td style={{ fontWeight: 600, textAlign: 'right' }}>
                  Rp. {total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
              </tr>

              <tr>
              <td colSpan={4} style={{ textAlign: 'right' }}>Discount</td>
                <td style={{ textAlign: 'right' }}>
                  Rp. {discount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
              </tr>
            </>
          )}

          <tr className={discount === 0 ? styles.tfoot : ''}>
            <td colSpan={3}></td>
            <td style={{ borderTop: discount > 0 ? '1px solid #D3D8DE' : undefined, fontWeight: 600, textAlign: 'right' }}>
              {discount === 0 ? 'Subtotal' : 'Total'}
            </td>
            <td style={{ borderTop: discount > 0 ? '1px solid #D3D8DE' : undefined, fontWeight: 600, textAlign: 'right' }}>
              Rp. {subTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
          </tr>

          <tr>
            <td colSpan={4} style={{ textAlign: 'right' }}>VAT</td>
            <td style={{ textAlign: 'right' }}>
              Rp. {ppn.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
          </tr>

          <tr>
            <td colSpan={3}></td>
            <td style={{ borderTop: '1px solid #D3D8DE', fontWeight: 600, textAlign: 'right' }}>
              Grand Total
            </td>
            <td style={{ borderTop: '1px solid #D3D8DE', fontWeight: 600, textAlign: 'right' }}>
              Rp. {grandTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
          </tr>
        </tbody>
      </table>

      <div className={styles.form}>
        <div>
          <span>Notes</span>
          <p>{newLineToBr(data.note)}</p>
        </div>

        <div className={styles.sign}>
          <p>Your Faithfully,</p>
          <p style={{ fontWeight: 600 }}>PT. Diatera Cipta Teknologi</p>
          <p style={{ marginTop: '48pt' }}>Sales Dept.</p>
        </div>
      </div>
    </div>
  );
};

class QuotationPrint extends React.PureComponent<{ data?: any }> {
  render() {
    if (this.props.data == null) {
      return (<></>);
    }

    return (
      <QuotatioinPrintFormat data={this.props.data} />
    );
  }
}

export default QuotationPrint;
