import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainBase from '../components/layouts/MainBase';
import NotFound from '../components/fragments/NotFound';
import { useTypedSelector } from '../../stores';
import { load } from './load';

const Page = load(lazy(() => import('../pages/Page')));

const ProtectedRoutes: React.FC = () => {
  const { routes, sidebar } = useTypedSelector(s => s.basis);
  const { token } = useTypedSelector(s => s.user).user;

  if (token === '') {
    return <Navigate replace to="/login" />;
  }

  return (
    <MainBase sidebarMenus={sidebar}>
      <Routes>
        {routes.map(route => {
          const { page } = route;

          if (Object.hasOwn(page, 'path') && Object.hasOwn(page, 'type')) {
            const { type } = page;
            const suffix = type === 'CRUDTable' || type === 'Route' ? '/*' : '';
            const path = route.href + suffix;
            
            return (
              <Route key={path} path={path} element={<Page page={page} />} />
            );
          }

          return (
            <Route key={route.href} path={route.href} element={<route.page />} />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </MainBase>
  );
}

export default ProtectedRoutes;