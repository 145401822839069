import Inventory from './Inventory';

const InventoryPage: Page = {
  path: '/',
  title: 'Inventory',
  type: 'CRUDTable',
  model: Inventory,
};

export default InventoryPage;
