import QuotationModel from './Quotation';

const QuotationPage: Page = {
  path: '/',
  title: 'Quotation',
  type: 'CRUDTable',
  model: QuotationModel,
};

export default QuotationPage;
