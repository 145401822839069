export function composeFields(model: Model) {
  const fields: Field[] = [];

  if (model.extend != null) {
    const filterLookUpHide = (f: Field) => !f.lookUpHide;
    const filteredFileds = model.extend.fields.filter(filterLookUpHide);

    for (let i = 0; i < filteredFileds.length; i++) {
      const field = { ...filteredFileds[i] };

      if (field.lookUp) {
        field.type = 'lookUp';
        field.lookUpModel = model.extend.name;
      }

      fields.push(field);
    }
  }

  fields.push(...model.fields);

  return fields;
}
